import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { getHeadings } from '../../components/Layout/TableOfContents/lib/helpers';
import {BackToTop} from '../../components/BackToTop';
import Contact from '../../components/contact';
import { Drawer } from '../../components/Drawer';

import { Sections, Layout, TableOfContentsDesktop, Article } from '../../components/Layout';
import { SectionContainer } from './SectionContainer';

export const Page = ({ pkg, content }) => {
  const { edges } = content;
  const _is_full_page = false;
  const [headings, setHeadings] = useState([]);
  const layoutRef = useCallback(node => {
    if (!node) {
      setHeadings([]);

      return;
    }

    setHeadings(getHeadings(node));
  }, []);

  return (
    <Layout hasSidebar={true} header={true} ref={layoutRef}>
      <Drawer />
      <Article>
        <Sections>
          {edges.map((edge, index) => (
            <SectionContainer key={`section-${index}`} fullPage={_is_full_page} edge={edge} edges={edges} pkg={pkg} />
          ))}
        </Sections>
        <BackToTop />
        <Contact />
      </Article>
      <TableOfContentsDesktop headings={headings} />
    </Layout>
  );
};

Page.propTypes = {
  pkg: PropTypes.shape({
    description: PropTypes.string
  }),
  content: PropTypes.shape({
    edges: PropTypes.array
  }),
  wide: PropTypes.bool
};
